import * as actionTypes from 'actions/liveCameraActions';

const initialState = {
  loading: false,
  trucks: [],
  selectedTrucks: [],
  filter: {
    online: true,
    offline: true
  },
  showTruck: []
};

const liveCameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_TRUCKS: {
      return {
        ...state,
        trucks: action.trucks,
        loading: false
      };
    }
    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_TRUCK: {
      return {
        ...state,
        selectedTrucks: action.selectedTrucks
      };
    }

    case actionTypes.SET_SHOW_TRUCK: {
      return {
        ...state,
        showTruck: action.showTruck
      };
    }

    default: {
      return state;
    }
  }
};

export default liveCameraReducer;
