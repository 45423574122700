import * as actionTypes from 'actions/roleManagementActions';

const initialState = {
  drivers: [],
  trucks: [],
  ipChannels: [],
  pages: [],
  currentType: '',
  currentRole: [],
  userName: '',
  filter: {
    warehouse_security: true,
    warehouse_manager: true,
    warehouse_operation: true
  },
  loading: false,
  selectedDriver: [],
  formState: {
    open: false,
    edit: false,
    username: '',
    fullname: '',
    role: 'driver',
    avatar: '',
    email: '',
    role_name: '',
    note: '',
    ngay_bat_dau: new Date('2022/1/1')
  }
};

const roleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DRIVER: {
      return {
        ...state,
        drivers: action.drivers,
        loading: false,
        selectedDriver: []
      };
    }

    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_DRIVER: {
      return {
        ...state,
        selectedDriver: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    case actionTypes.UPDATE_RESOURCE_TYPE: {
      return {
        ...state,
        currentType: action.currentType
      };
    }

    case actionTypes.UPDATE_USERNAME: {
      console.log('action.currentRole', action.currentRole);
      return {
        ...state,
        userName: action.userName,
        currentRole: action.currentRole
      };
    }

    case actionTypes.INIT_DATA: {
      return {
        ...state,
        trucks: action.trucks,
        ipChannels: action.ipChannels,
        pages: action.pages,
        currentType: 'page'
      };
    }

    default: {
      return state;
    }
  }
};

export default roleManagementReducer;
