import { getUsersApi, truckListApi, listIpChannel, getPagesApi } from 'apis';

const prefix = 'roleManagement';

export const SET_DRIVER = `${prefix}SET_DRIVER`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_DRIVER = `${prefix}SET_SELECTED_DRIVER`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_DRIVER`;
export const SET_FILTER = `${prefix}SET_FILTER_DRIVER`;
export const INIT_DATA = `${prefix}INIT_DATA`;
export const UPDATE_RESOURCE_TYPE = `${prefix}UPDATE_RESOURCE_TYPE`;
export const UPDATE_USERNAME = `${prefix}UPDATE_USERNAME`;

export const setDrivers = drivers => dispatch => {
  dispatch({
    type: SET_DRIVER,
    drivers
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getDrivers = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const drivers = await getUsersApi();
    dispatch(setDrivers(drivers));
  } catch (error) {
    console.log('get Drivers error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedDriver = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_DRIVER,
    selected
  });
};

export const updateForm = formState => dispatch => {
  if (formState.open === false) dispatch(getDrivers());
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER,
    filter
  });
};

export const updateResourceType = currentType => dispatch => {
  dispatch({
    type: UPDATE_RESOURCE_TYPE,
    currentType
  });
};

export const updateUserName = (userName, currentRole) => dispatch => {
  dispatch({
    type: UPDATE_USERNAME,
    userName,
    currentRole
  });
};

export const initData = () => async dispatch => {
  dispatch(getDrivers());
  try {
    const trucks = await truckListApi();
    const ipChannels = await listIpChannel();
    const pages = await getPagesApi();

    console.log('trucks', trucks);
    console.log('ipChannels', ipChannels);
    console.log('pages', pages);

    dispatch({
      type: INIT_DATA,
      trucks,
      ipChannels,
      pages
    });
  } catch (error) {
    console.log('lỗi khi fetch data list');
  }
};
