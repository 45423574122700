export const UPDATE_ROUTER = 'UPDATE_ROUTER';
export const UPDATE_MARKER_DATA = 'UPDATE_MARKER_DATA';
export const UPDATE_VIDEOS_DATA = 'UPDATE_VIDEOS_DATA';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const UPDATE_SUM_DATA = 'UPDATE_SUM_DATA';
export const UPDATE_LINK_DOWLOAD = 'UPDATE_LINK_DOWLOAD';

export const updateTable = tableData => dispatch =>
  dispatch({
    type: UPDATE_TABLE,
    tableData
  });

export const updateMarkerData = markers => dispatch =>
  dispatch({
    type: UPDATE_MARKER_DATA,
    markers
  });

export const updateRouter = route => dispatch =>
  dispatch({
    type: UPDATE_ROUTER,
    route
  });

export const setVideosData = videos => dispatch =>
  dispatch({
    type: UPDATE_VIDEOS_DATA,
    videos
  });
export const updateSumData = data => dispatch =>
  dispatch({
    type: UPDATE_SUM_DATA,
    data
  });
export const updateLinkDownload = link => dispatch =>
  dispatch({
    type: UPDATE_LINK_DOWLOAD,
    link
  });
