import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  papers: [],
  selectedPaper: [],
  formAddPaper: {
    open: false
  },
  formViewPaper: {
    open: false,
    image: ''
  },
  plate: ''
};

const paperReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAPERS: {
      return {
        ...state,
        papers: action.papers,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_PAPER: {
      return {
        ...state,
        selectedPaper: action.selectedPaper
      };
    }

    case actionTypes.OPEN_FORM_ADD_PAPER: {
      return {
        ...state,
        formAddPaper: {
          ...state.formAddPaper,
          ...{ open: action.open, paper: action.paper }
        }
      };
    }
    case actionTypes.OPEN_FORM_VIEW_PAPER: {
      return {
        ...state,
        formViewPaper: {
          ...state.formViewPaper,
          ...{ open: action.open, image: action.image }
        }
      };
    }

    case actionTypes.DELETE_PAPER: {
      return {
        ...state,
        selectedPaper: []
      };
    }

    default: {
      return state;
    }
  }
};

export default paperReducer;
