import { request } from 'utils/axios';
import moment from 'moment';

function handleError(message, error) {
  if (error.response) {
    console.log(message, error.response.data);
    return error.response.data;
  }
  return console.log(message, error);
}

export async function loginApi(userName, password) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/user/login',
      data: {
        username: userName,
        password: password
      }
    });
    return response;
  } catch (error) {
    handleError('login', error);
  }
}

export async function getUserInfo(userName) {
  const response = await request.request({
    method: 'POST',
    url: '/user/get_info',
    data: {
      username: userName
    }
  });
  return response.data;
}

export async function request_demo(data) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/form/requeset_demo',
      data: data
    });
    return response;
  } catch (error) {
    handleError('request_demo', error);
  }
}

export async function confirmTokenApi() {
  const response = await request.request({
    method: 'GET',
    url: '/user/validate'
  });
  return response;
}

export async function currentListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_current_list'
    });
    return response;
  } catch (error) {
    handleError('currentListApi', error);
  }
}

export async function driverListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver/driver_list'
    });
    return response;
  } catch (error) {
    handleError('driverListApi', error);
  }
}

export async function driverReportApi(driverName, start, end) {
  const name =
    driverName || driverName !== '' ? { driver_name: driverName } : {};

  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_list_by_date',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        ...name
      }
    });
    return response.data;
  } catch (error) {
    handleError('driverReportApi', error);
  }
}

export async function driverRouteApi(driverName, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_driver_route_report',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        driver_name: driverName
      }
    });
    return response.data;
  } catch (error) {
    handleError('driverRouteApi', error);
  }
}

export async function abnormalActivityApi(driverName, start, end) {
  const name =
    driverName || driverName !== '' ? { driver_name: driverName } : {};

  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/abnormal_activity',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        ...name
      }
    });
    return response.data;
  } catch (error) {
    handleError('abnormalActivityApi', error);
  }
}

export async function listExcelApi(start, end) {
  let time = {};

  if (start || start !== '')
    time = {
      start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(end).format('YYYY-MM-DD HH:mm:ss')
    };

  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/get_list',
      data: {
        ...time
      }
    });
    return response.data;
  } catch (error) {
    handleError('listExcelApi', error);
  }
}

export async function openFileExcelApi(fileId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/open_file',
      data: {
        file_id: fileId
      }
    });
    return response.data;
  } catch (error) {
    handleError('openFileExcelApi', error);
  }
}

export async function openContractInfoApi(contractId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_info',
      data: {
        contract_id: contractId
      }
    });
    return response.data;
  } catch (error) {
    handleError('openOrderInfoApi', error);
  }
}

export async function deleteContractInfoApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/delete',
      data: {
        list_contract: ids
      }
    });
    return response.data;
  } catch (error) {
    handleError('deleteContractInfoApi', error);
  }
}

export async function listStopAndVideoApi(plate, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_list_stop_and_video',
      data: {
        plate: plate,
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss')
      }
    });
    return response.data;
  } catch (error) {
    handleError('listStopAndVideoApi', error);
  }
}

export async function listMarkerApi() {
  const response = await request.request({
    method: 'POST',
    url: '/marker/get_all_list'
  });
  return response.data;
}

export function uploadFileApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('file', file);

    return request.post('/excel/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    handleError('uploadFile', error);
  }
}

export async function downloadFileApi(fileId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/download',
      data: {
        file_id: fileId
      }
    });
    return response.data;
  } catch (error) {
    handleError('downloadFile', error);
  }
}

export async function deleteFileApi(files) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/delete',
      data: {
        files
      }
    });
    return response.data;
  } catch (error) {
    handleError('deleteFile', error);
  }
}

export async function currentTruckListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/get_current_truck_list'
    });
    return response.data;
  } catch (error) {
    handleError('currentTruckListApi', error);
  }
}

export async function listVideoBusinessApi(truckPlate, start, end, camNumber) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/etracking/get_list_by_date',
      data: {
        truck_plate: truckPlate,
        start_date: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        cam_number: camNumber
      }
    });
    return response.data;
  } catch (error) {
    handleError('listVideoBusinessApi', error);
  }
}

export async function truckListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/truck_list'
    });
    return response.data;
  } catch (error) {
    handleError('truckListApi', error);
  }
}

export async function routeFromVideoApi(plate, start) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/get_route_from_video',
      data: {
        plate,
        start_time: start
      }
    });
    return response.data;
  } catch (error) {
    handleError('routeFromVideoApi', error);
  }
}

export async function addPoint(data) {
  const response = await request.request({
    method: 'POST',
    url: '/marker/create',
    data: data
  });
  return response;
}

export async function removePointApi(listPoint) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/marker/delete',
      data: {
        list_coor: listPoint
      }
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    handleError('removePointApi', error.response);
  }
}

export function uploadPointFileApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/marker/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    handleError('uploadPointFileApi', error);
  }
}

export async function updateContractApi(dataUpdate) {
  try {
    const response = await request.request({
      method: 'PUT',
      url: '/contract/update',
      data: dataUpdate
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    handleError('updateContractApi', error.response);
  }
}

export async function routeDataApi(plate, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_route_data',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        plate
      }
    });
    return response.data;
  } catch (error) {
    handleError('routeDataApi', error);
  }
}

export async function imageListApi(plate, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/image/get_list',
      data: {
        truck_plate: plate,
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss')
      }
    });
    return response.data;
  } catch (error) {
    handleError('imageListApi', error);
  }
}

export async function addContact(data) {
  const response = await request.request({
    method: 'POST',
    url: '/contract/create',
    data
  });
  return response.data;
}

export async function listTruckApi() {
  const response = await request.request({
    method: 'POST',
    url: '/truck/get_list'
  });
  return response.data;
}

export async function addTruckApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/truck/create',
    data
  });
  return response.data;
}

export async function updateTrucksApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/truck/update',
    data
  });
  return response.data;
}

export async function deleteTrucksApi(listVid) {
  const response = await request.request({
    method: 'POST',
    url: '/truck/delete',
    data: {
      list_vid: listVid
    }
  });
  return response.data;
}

export async function listWarningApi() {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/list_canh_bao',
    data: {
      username: 'driver_demo'
    }
  });
  return response.data;
}

export async function addWarningApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/tao_canh_bao',
    data
  });
  return response.data;
}

export async function updateWarningApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/cap_nhat',
    data
  });
  return response.data;
}

export async function deleteWarningApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/xoa_canh_bao',
    data: {
      id_canh_bao: ids
    }
  });
  return response.data;
}

export async function listRuleApi(plate) {
  const data = plate && plate != '' ? { plate } : {};
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/get_rules',
    data
  });
  return response.data;
}

export async function addRuleApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/add_rule',
    data
  });
  return response.data;
}

export async function updateRuleApi(data) {
  if (data.total_km && typeof data.total_km == 'string')
    data.total_km = Number(data.total_km);
  const response = await request.request({
    method: 'PUT',
    url: '/truck_info/edit_rule',
    data
  });
  return response.data;
}

export async function deleteRuleApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/delete_rules',
    data: {
      data: ids
    }
  });
  return response.data;
}

export async function listPaperApi(plate) {
  const data = plate && plate != '' ? { plate } : {};
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/get_list_paper',
    data
  });
  return response.data;
}

const createFormData = (files, body = {}) => {
  const data = new FormData();

  files.map(photo => {
    data.append('files', photo);
  });

  Object.keys(body).forEach(key => {
    data.append(key, body[key]);
  });
  return data;
};

export async function addPaperApi(files, body, onUploadProgress) {
  try {
    const formData = createFormData(files, body);

    return request.post('/truck_info/add_paper', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    handleError('addPaperApi', error);
  }
}

export async function deletePaperApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/delete_paper',
    data: {
      data: ids
    }
  });
  return response.data;
}

export async function listIpChannel() {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/list_ip_channel'
  });
  return response.data;
}

export async function warehouseVideos(payload) {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/get_list_video',
    data: {
      channel: payload['channel'],
      ip: payload['ip'],
      start_date: moment(payload['start_date']).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(payload['end_date']).format('YYYY-MM-DD HH:mm:ss'),
    }
  });
  return response.data;
}

export async function getNoticesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/warehouse/notification_list'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getNoticeNumberApi() {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/get_noti_tag_number'
  });
  return response.data;
}

export async function updateLastNoticeDate() {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/update_last_noti_date'
  });
  return response.data;
}

export async function getEmailsApi() {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/get_email_list'
  });
  return response.data;
}

export async function addEmailApi(email) {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/add_email',
    data: { email }
  });
  return response.data;
}

export async function deleteEmailApi(email) {
  const response = await request.request({
    method: 'POST',
    url: '/warehouse/delete',
    data: { email }
  });
  return response.data;
}

export async function createUserApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/user/create',
    data
  });
  return response.data;
}

export async function deleteUserApi(username) {
  const response = await request.request({
    method: 'DELETE',
    url: '/user/delete',
    data: {
      username
    }
  });
  return response.data;
}

export async function resetPasswordApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/user/reset_password',
    data
  });
  return response.data;
}

export async function updateUserApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/user/update_info',
    data
  });
  return response.data;
}

export function uploadTripImageApi(files, onUploadProgress) {
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    return request.post('/tms/don_hang/upload_image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadTripImageApi', error);
  }
}

export async function getUsersApi() {
  const response = await request.request({
    method: 'POST',
    url: '/quanlylogi/roles/get_list_users'
  });
  return response.data;
}

export async function getResourcesApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/quanlylogi/roles/get_resources',
    data
  });
  return response.data;
}

export async function addResourcesApis(data) {
  const response = await request.request({
    method: 'POST',
    url: '/quanlylogi/roles/add_resources',
    data
  });
  return response.data;
}

export async function getPagesApi() {
  const response = await request.request({
    method: 'POST',
    url: '/quanlylogi/roles/all_pages'
  });
  return response.data;
}
