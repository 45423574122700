import { listPaperApi, deletePaperApi } from 'apis';

export const SET_PAPERS = 'SET_PAPERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_PAPER = 'SET_SELECTED_PAPER';
export const OPEN_FORM_ADD_PAPER = 'OPEN_FORM_ADD_PAPER';
export const OPEN_FORM_VIEW_PAPER = 'paper/OPEN_FORM_VIEW_PAPER';
export const DELETE_PAPER = 'DELETE_PAPER';

export const getPapers = plate => async dispatch => {
  try {
    dispatch(setLoading(true));
    const papers = await listPaperApi(plate);
    dispatch({
      type: SET_PAPERS,
      papers
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setSelectedPaper = selectedPaper => dispatch => {
  dispatch({
    type: SET_SELECTED_PAPER,
    selectedPaper
  });
};

export const openFormAddPaper = (open, paper) => dispatch => {
  dispatch({
    type: OPEN_FORM_ADD_PAPER,
    open,
    paper
  });
};

export const openFormViewPaper = (open, image) => dispatch => {
  dispatch({
    type: OPEN_FORM_VIEW_PAPER,
    open,
    image
  });
};

export const deletePaper = papers => async dispatch => {
  try {
    await deletePaperApi(papers);
    dispatch({
      type: DELETE_PAPER,
      papers
    });
  } catch (error) {
    console.log('Delete paper', error);
  }
};
