export const UPDATE_WAY_POINTS = 'cameraPlayBack/UPDATE_WAY_POINTS';
export const UPDATE_MARKER = 'cameraPlayBack/UPDATE_MARKER';
export const SET_VIDEOS = 'cameraPlayBack/SET_VIDEOS';
export const SET_LOADING = 'cameraPlayBack/SET_LOADING';
export const SET_LINK_VIDEO = 'cameraPlayBack/SET_LINK_VIDEO';
export const SET_VIEW_VIDEO = 'cameraPlayBack/SET_VIEW_VIDEO';
export const RESET_VIEW_VIDEO = 'cameraPlayBack/RESET_VIEW_VIDEO';

export const updateVideoPlayBackWayPoints = wayPoints => dispatch =>
  dispatch({
    type: UPDATE_WAY_POINTS,
    wayPoints
  });

export const updateVideoPlayBackMarker = marker => dispatch =>
  dispatch({
    type: UPDATE_MARKER,
    marker
  });

export const setVideosPlayBack = (videos, plate) => dispatch =>
  dispatch({
    type: SET_VIDEOS,
    videos,
    plate
  });

export const setLoading = loading => dispatch =>
  dispatch({
    type: SET_LOADING,
    loading
  });

export const setLinkVideo = linkVideo => dispatch =>
  dispatch({
    type: SET_LINK_VIDEO,
    linkVideo
  });
export const setVideoView = linkVideo => dispatch => {
  dispatch({
    type: SET_VIEW_VIDEO,
    linkVideo: linkVideo
  });
};

export const resetViewVideo = () => dispatch => {
  dispatch({
    type: RESET_VIEW_VIDEO
  });
};
