import { useDispatch, useSelector } from 'react-redux';
import { confirmTokenApi, getUserInfo } from 'apis';
import { getTruckList, setInfo } from 'actions';

export const useInitStoreAndConfirmToken = async router => {
  const dispatch = useDispatch();
  const { loadTruck } = useSelector(state => state.app);
  const { user } = useSelector(state => state.session);
  try {
    await confirmTokenApi(router);
    if (!loadTruck) dispatch(getTruckList());
    if (user.username === '') {
      const userName = window.localStorage.getItem('matar_username');
      const userInfo = await getUserInfo(userName);
      dispatch(setInfo(userInfo));
    }
  } catch (error) {
    router.history.push('/auth/login');
  }
};
