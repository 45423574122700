import * as actionTypes from 'actions/warningActions';

const initialState = {
  loading: false,
  warnings: [],
  selectedWarning: [],
  showWarning: [],
  formAddWarning: {
    open: false,
    gps: null
  },
  formViewWarning: {
    open: false,
    image: ''
  },
  addNewWarning: false
};

const warningReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WARNING: {
      return {
        ...state,
        warnings: action.warnings,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_WARNING: {
      return {
        ...state,
        selectedWarning: action.selectedWarning
      };
    }

    case actionTypes.OPEN_FORM_ADD_WARNING: {
      return {
        ...state,
        formAddWarning: action.formAddWarning
      };
    }
    case actionTypes.OPEN_FORM_VIEW_WARNING: {
      return {
        ...state,
        formViewWarning: {
          ...state.formViewWarning,
          ...{ open: action.open, image: action.image }
        }
      };
    }

    case actionTypes.SET_SHOW_WARNING: {
      return {
        ...state,
        showWarning: action.showWarning
      };
    }

    case actionTypes.SET_ADD_NEW_WARNING: {
      return {
        ...state,
        addNewWarning: action.flag
      };
    }

    case actionTypes.DELETE_WARNING: {
      return {
        ...state,
        selectedWarning: []
      };
    }

    default: {
      return state;
    }
  }
};

export default warningReducer;
