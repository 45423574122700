import { getEmailsApi, listIpChannel, warehouseVideos } from 'apis';

export const UPDATE_WAY_POINTS = 'warehouseVideoActions/UPDATE_WAY_POINTS';
export const UPDATE_MARKER = 'warehouseVideoActions/UPDATE_MARKER';
export const SET_VIDEOS = 'warehouseVideoActions/SET_VIDEOS';
export const SET_LOADING = 'warehouseVideoActions/SET_LOADING';
export const SET_LINK_VIDEO = 'warehouseVideoActions/SET_LINK_VIDEO';
export const SET_LIST_CHANNEL = 'warehouseVideoActions/SET_LIST_CHANNEL';
export const SET_VIEW_VIDEO = 'warehouseVideoActions/SET_VIEW_VIDEO';
export const RESET_VIEW_VIDEO = 'warehouseVideoActions/RESET_VIEW_VIDEO';
export const SET_EMAILS = 'warehouseVideoActions/SET_EMAILS';
export const SET_LOADING_EMAIL = 'warehouseVideoActions/SET_LOADING_EMAIL';

export const updateVideoPlayBackWayPoints = wayPoints => dispatch =>
  dispatch({
    type: UPDATE_WAY_POINTS,
    wayPoints
  });

export const updateVideoPlayBackMarker = marker => dispatch =>
  dispatch({
    type: UPDATE_MARKER,
    marker
  });

export const setVideos = videos => dispatch =>
  dispatch({
    type: SET_VIDEOS,
    videos
  });

export const setLoading = loading => dispatch =>
  dispatch({
    type: SET_LOADING,
    loading
  });

export const setLinkVideo = linkVideo => dispatch =>
  dispatch({
    type: SET_LINK_VIDEO,
    linkVideo
  });

export const getListChannel = () => async dispatch => {
  try {
    const res = await listIpChannel();
    dispatch({
      type: SET_LIST_CHANNEL,
      listChannel: res
    });
  } catch (error) {
    console.log(error);
  }
};

export const getVideos = data => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await warehouseVideos(data);
    dispatch(setVideos(res));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setVideoView = linkVideo => dispatch => {
  dispatch({
    type: SET_VIEW_VIDEO,
    linkVideo: linkVideo
  });
};

export const resetViewVideo = () => dispatch => {
  dispatch({
    type: RESET_VIEW_VIDEO
  });
};

export const getEmails = () => async dispatch => {
  try {
    dispatch(setLoadingEmail(true));
    const res = await getEmailsApi();

    dispatch(setEmails(res));
  } catch (error) {
    dispatch(setLoadingEmail(false));
  }
};

export const setEmails = emails => dispatch => {
  dispatch({
    type: SET_EMAILS,
    emails: emails
  });
};

export const setLoadingEmail = loadingEmail => dispatch =>
  dispatch({
    type: SET_LOADING_EMAIL,
    loadingEmail
  });
