import * as actionTypes from 'actions';

const initialState = {
  route: [],
  markers: [],
  video: [],
  tableData: [],
  sum_data:[],
  link:''
};

const stopPlateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TABLE: {
      return {
        ...state,
        tableData: action.tableData,
      };
    }
    case actionTypes.UPDATE_MARKER_DATA: {
      return {
        ...state,
        markers: action.markers,
      };
    }

    case actionTypes.UPDATE_ROUTER: {
      return {
        ...state,
        route: action.route
      };
    }
    case actionTypes.UPDATE_VIDEOS_DATA: {
      return {
        ...state,
        video: action.videos,
      };
    }
    case actionTypes.UPDATE_SUM_DATA: {
      return {
        ...state,
        sum_data: action.data,
      };
    }
    case actionTypes.UPDATE_LINK_DOWLOAD: {
      return {
        ...state,
        link: action.link,
      };
    }
    default: {
      return state;
    }
  }
};

export default stopPlateReducer;
