import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import drivingReportReducer from './drivingReportReducer';
import cameraPlaybackReducer from './cameraPlaybackReducer';
import excelReportReducer from './excelReportReducer';
import excelReportDetailReducer from './excelReportDetailReducer';
import imageReducer from './imageReducer';
import appReducer from './appReducer';
import locationReducer from './locationReducer';
import liveCameraReducer from './liveCameraReducer';
import truckReducer from './truckReducer';
import warningReducer from './warningReducer';
import ruleReducer from './ruleReducer';
import paperReducer from './paperReducer';
import stopPlateReducer from './stopPlateReducer';
import warehouseVideoReducer from './warehouseVideoReducer';
import noticeManagementReducer from './noticeManagementReducer';
import driverManagementReducer from './driverManagementReducer';
import roleManagementReducer from './roleManagementReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  drivingReport: drivingReportReducer,
  cameraPlayback: cameraPlaybackReducer,
  excelReport: excelReportReducer,
  excelReportDetail: excelReportDetailReducer,
  imageReducer: imageReducer,
  app: appReducer,
  location: locationReducer,
  liveCamera: liveCameraReducer,
  truckReducer: truckReducer,
  warningReducer: warningReducer,
  ruleReducer: ruleReducer,
  paperReducer: paperReducer,
  stopPlateReducer: stopPlateReducer,
  warehouseVideoReducer: warehouseVideoReducer,
  noticeManagementReducer: noticeManagementReducer,
  driverManagementReducer: driverManagementReducer,
  roleManagementReducer: roleManagementReducer
});

export default rootReducer;
