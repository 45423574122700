import * as actionTypes from 'actions/cameraPlaybackActions';

const initLinkVideoView = {
  cam1: '',
  cam2: '',
  cam3: '',
  cam4: ''
};

const initialState = {
  loading: false,
  wayPoints: [],
  markers: [],
  videos: [],
  countActive: 0,
  linkVideo: [],
  listVideoView: initLinkVideoView
};

const cameraPlaybackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_WAY_POINTS: {
      return {
        ...state,
        wayPoints: action.wayPoints
      };
    }

    case actionTypes.UPDATE_MARKER: {
      return {
        ...state,
        markers: action.marker
      };
    }
    case actionTypes.SET_VIDEOS: {
      return {
        ...state,
        videos: action.videos,
        loading: false,
        linkVideo: []
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_LINK_VIDEO: {
      return {
        ...state,
        linkVideo: action.linkVideo
      };
    }

    case actionTypes.SET_VIEW_VIDEO: {
      const camNumber = state.countActive < 4 ? state.countActive + 1 : 1;
      return {
        ...state,
        listVideoView: {
          ...state.listVideoView,
          ...{
            [`cam${camNumber}`]: action.linkVideo
          }
        },
        countActive: camNumber
      };
    }

    case actionTypes.RESET_VIEW_VIDEO: {
      return {
        ...state,
        listVideoView: initLinkVideoView,
        countActive: 0
      };
    }

    default: {
      return state;
    }
  }
};

export default cameraPlaybackReducer;
